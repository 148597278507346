export const translations = {
  '_lp:hero':
  {
    // en: 'From lead to close, we handle the clicks so you can close the deals',
    en: 'Cut your CRM data entry time by 90%',
    fr: 'Réduisez votre temps de saisie CRM de 90%'
  },
  'no-code': {
    fr: 'sans code',
  },
  '_lp:page_title': {
    en: 'AI powered CRM automation, no-code',
    fr: 'Automatisez votre CRM grâce à l\'IA, sans code'
  },
  '_lp:desc': {
    en: 'Automate CRM data entry, lead qualification and more. With a multi-channel approach, Plurally integrates with your existing CRM and tools to save your teams valuable time and avoid information loss.',
    fr: 'Automatisez la saisie de données CRM, la qualification des prospects et plus encore. Avec une approche multi-canal, Plurally s\'intègre à votre CRM et vos outils existants pour faire gagner un temps précieux à vos équipes et éviter les pertes d\'information.'
  },
  '_lp:h1': {
    en: 'Automate CRM data entry, lead qualification and more.',
    fr: 'Automatisez la saisie de données CRM, la qualification des prospects et plus encore.'
  },
  '_lp:h1:sub': {
    en: 'With a multi-channel approach, Plurally integrates with your existing CRM and tools to save your teams valuable time and avoid information loss.',
    fr: 'Avec une approche multi-canal, Plurally s\'intègre à votre CRM et vos outils existants pour faire gagner un temps précieux à vos équipes et éviter les pertes d\'information.'
  },
  '_lp:pricing:title': {
    en: "Let's build a unique pricing for you",
    fr: "Créons le tarif qui vous convient",
  },
  '_lp:pricing:subtitle': {
    en: 'Pricing',
    fr: 'Tarifs',
  },
  '_lp:pricing:desc': {
    en: 'We offer a range of pricing options to suit your needs. Whether you’re a small business, a growing startup, or an established enterprise, we’ve got you covered.',
    fr: 'Nous proposons une large gamme d\'options tarifaires pour répondre à vos besoins. Que vous soyez une petite entreprise, une start-up en croissance ou une entreprise établie, nous avons ce qu\'il vous faut.'
  },
  '_faq:what_is_plurally:title': {
    en: 'What is Plurally?',
    fr: 'Qu\'est-ce que Plurally ?'
  },
  '_faq:what_is_plurally:p1': {
    en: 'Plurally is a no-code platform that allows you to automate CRM data entry, lead qualification, and other repetitive tasks. It integrates with your existing CRM and tools to create custom workflows that save you time and money.',
    fr: 'Plurally est une plateforme sans code qui vous permet d\'automatiser la saisie de données CRM, la qualification des prospects et autres tâches répétitives. Elle s\'intègre à votre CRM et vos outils existants pour créer des workflows personnalisés qui vous font gagner du temps et de l\'argent.'
  },
  '_faq:what_is_plurally:p2': {
  },
  '_faq:how_do_i_know:title': {
    en: 'How do I know that Plurally won\'t make changes to parts I do not wish to or corrupt my CRM records?',
    fr: 'Comment être sûr que Plurally ne fera pas de modifications non souhaitées ou ne corrompra pas mon CRM ?'
  },
  '_faq:how_do_i_know:p1': {
    en: 'Plurally is fully parametrizable and you can choose which records or fields of your CRM it is allowed to access, create or modify. After every action, Plurally will provide you with a detailed log of the changes made so that you can review them and modify them if you wish to add additional information.',
    fr: 'Plurally est entièrement paramétrable et vous pouvez choisir les objets ou champs de votre CRM auxquels vous autorisez l\'IA à accéder, créer ou modifier. Après chaque action, Plurally vous fournira un journal détaillé des modifications apportées pour que vous puissiez les examiner et les modifier si vous souhaitez ajouter des informations supplémentaires.'
  },
  '_faq:data_privacy:title': {
    en: 'What do you do with my data?',
    fr: 'Que faites-vous avec mes données ?'
  },
  '_faq:data_privacy:p1': {
    en: 'Plurally is fully GDPR compliant and we take your data privacy very seriously.',
    fr: 'Plurally est entièrement conforme au RGPD et nous prenons la protection de vos données très au sérieux.',
  },
  '_faq:data_privacy:p2': {
    en: 'Your data (email, DMs, CRM records etc...) is used only for executing your flows. It is never used for any other purpose. That means that your data is not used for training AI models or shared commercially with third parties.',
    fr: 'Vos données (e-mails, messages privés, enregistrements CRM, etc.) sont utilisées uniquement pour exécuter vos workflows. Elles ne sont jamais utilisées à d\'autres fins. Cela signifie que vos données ne sont pas utilisées pour entraîner des modèles d\'IA ou partagées commercialement avec des tiers.'
  },
  '_faq:data_privacy:p3': {
    en: 'Your data are accessed on a per-need basis and is not stored on our servers.',
    fr: 'Vos données sont accédées au cas par cas et ne sont pas stockées sur nos serveurs.'
  },
  '_faq:data_privacy:p4': {
    en: 'We use SSL technology to encrypt your data and ensure that it is secure.',
    fr: 'Nous utilisons la technologie SSL pour crypter vos données et garantir leur sécurité.'
  },
  '_faq:data_privacy:strong': {
    en: 'We do not store nor make money from your data.',
    fr: 'Nous ne stockons pas vos données et ne les monétisons pas.'
  },
  '_faq:passwords:title': {
    en: 'For integrating with services, do I need to provide with my password?',
    fr: 'Pour intégrer des services, dois-je fournir mon mot de passe ?'
  },
  '_faq:passwords:p1': {
    en: 'When available we use industry standard OAuth2 authentication to connect with services. This means that you do not need to share your password with us and you can revoke access at any time.',
    fr: 'Lorsque cela est possible, nous utilisons l\'authentification OAuth2 standard de l\'industrie pour nous connecter aux services. Cela signifie que vous n\'avez pas besoin de partager votre mot de passe avec nous et que vous pouvez révoquer l\'accès à tout moment.'
  },
  '_faq:passwords:p2': {
    en: 'In some cases where OAuth2 is not available, we use API keys which are stored securely and encrypted on our servers.They can also be revoked at any time.',
    fr: 'Dans certains cas où OAuth2 n\'est pas disponible, nous utilisons des clés API qui sont stockées de manière sécurisée et cryptées sur nos serveurs. Elles peuvent également être révoquées à tout moment.'
  },
  '_faq:passwords:p3': {
    en: 'In some cases where API keys are not available, we will guide you on how to create app-specific passwords which are stored securely and encrypted on our servers.',
    fr: 'Dans certains cas où les clés API ne sont pas disponibles, nous vous guiderons sur la création de mots de passe spécifiques à l\'application qui sont stockés de manière sécurisée et cryptés sur nos serveurs.'
  },
  '_faq:passwords:strong': {
    en: 'In summary, we never ask for your personal password, for any service.',
    fr: 'En résumé, nous ne demandons jamais votre mot de passe personnel, pour aucun service.'
  },
  '_lp:use_cases:title': {
    en: 'Our workflows optimized, your processes simplified',
    fr: 'Nos workflows optimisés, vos processus simplifiés'
  },
  '_lp:use_cases:p': {
    en: 'We build tailored AI powered workflows that fit your specific use cases. Discover below how Plurally can help you automate your CRM and save your teams valuable time while avoiding information loss.',
    fr: 'Nous créons des workflows personnalisés alimentés par l\'IA qui correspondent à vos cas d\'usage spécifiques. Découvrez ci-dessous comment Plurally peut vous aider à automatiser votre CRM et à faire gagner un temps précieux à vos équipes tout en évitant les pertes d\'information.'
  },
  '_lp:getting_started:p': {
    en: 'We assist you to build tailored workflows that fit your specific use cases. You can also choose from a variety of templates and edit it directly from our no-code editor.',
    fr: 'Nous vous assistons pour créer des workflows personnalisés qui correspondent à vos cas d\'usage spécifiques. Vous pouvez également choisir parmi une variété de modèles et les éditer directement depuis notre éditeur. Pas besoin de code.'
  },
  '_lp:animated_demo:call_created:head': {
    en: 'New call created: Call with John',
    fr: 'Nouvel appel créé : Appel avec John'
  },
  '_lp:animated_demo:call_created:summary': {
    en: 'John and Alex discussed about increasing the number of users for SupaCompany from 10 to 50.',
    fr: 'John et Alex ont discuté de l\'augmentation du nombre d\'utilisateurs pour SupaCompany de 10 à 50.'
  },
  '_lp:animated_demo:contact_created:head': {
    en: 'New contact john@supacompany.com created',
    fr: 'Nouveau contact john@supacompany.com créé'
  },
  '_lp:animated_demo:contact_company_assoc:head': {
    en: 'Contact john@supacompany.com associated with SupaCompany',
    fr: 'Contact john@supacompany.com associé à SupaCompany'
  },
  '_lp:animated_demo:contact_call_assoc:head': {
    en: 'Contact john@supacompany.com associated with call "Call with John"',
    fr: 'Contact john@supacompany.com associé à l\'appel "Appel avec John"',
  },
  '_lp:animated_demo:contact_deal_assoc:head': {
    en: 'Contact john@supacompany.com associated with deal #1234',
    fr: 'Contact john@supacompany.com associé à l\'affaire #1234',
  },
  '_lp:animated_demo:call_created:hs_call_title': {
    en: 'Call with John (new COO) about increasing users count',
    fr: 'Appel avec John (nouveau COO) à propos de l\'augmentation du nombre d\'utilisateurs',
  },
  '_lp:animated_demo:call_created:hs_call_body': {
    en: 'John and Alex discussed about increasing the number of users for SupaCompany from 10 to 50 and to postpone signature to 18th of January 2025... (continued)',
    fr: 'John et Alex ont discuté de l\'augmentation du nombre d\'utilisateurs pour SupaCompany de 10 à 50 et de repousser la signature au 18 janvier 2025... (suite)',
  },
  '_lp:animated_demo:deal_updated:head': {
    en: 'Deal #1234 updated',
    fr: 'Affaire #1234 mise à jour',
  },
  '_lp:animated_demo:deal_updated:amount:before': {
    en: '$1,000',
    fr: '1 000 €',
  },
  '_lp:animated_demo:deal_updated:amount:after': {
    en: '$4,200',
    fr: '4 200 €',
  },
  '_lp:animated_demo:monitoring:p': {
    en: 'The following actions were taken automatically by Plurally in your CRM',
    fr: 'Les actions suivantes ont été prises automatiquement par Plurally dans votre CRM',
  },
  '_lp:step1:p1:1': {
    en: 'wants to connect to your',
    fr: 'souhaite se connecter à votre compte',
  },
  '_lp:step1:p1:2': {
    en: 'account.',
    fr: '.',
  },
  '_lp:step1:p2': {
    en: 'We never store your data. We only use it to run your flows!',
    fr: 'Nous ne stockons jamais vos données. Nous les utilisons uniquement pour exécuter vos workflows !',
  },
  '_hubspot:hs_timestamp': {
    en: 'Engagement date',
    fr: 'Date de l\'engagement',
  },
  '_hubspot:hs_call_title': {
    en: 'Call title',
    fr: 'Objet de l\'appel'
  },
  '_hubspot:hs_call_body': {
    en: 'Call notes',
    fr: 'Notes de l\'appel'
  },
  '_demo:automatic_sync_after_call:title': {
    en: 'Sync CRM after a call / meeting',
    fr: 'Synchronisez votre CRM après un appel / une réunion',
  },
  '_demo:automatic_sync_after_call:annotation-1': {
    en: 'Plurally fetches your meetings / calls information.',
    fr: 'Plurally récupère les informations de vos réunions / appels.'
  },
  '_demo:automatic_sync_after_call:annotation-2': {
    en: 'Plurally updates your HubSpot records with the latest information.',
    fr: 'Plurally met à jour vos enregistrements HubSpot avec les dernières informations.'
  },
  '_demo:automatic_inbound_qualification:title': {
    en: 'Automatically qualify inbound leads',
    fr: 'Qualifiez automatiquement les leads entrants',
  },
  '_demo:automatic_lead_qualification:annotation-1': {
    en: 'Plurally scans your inbound canals (email, social media...) for new leads.',
    fr: 'Plurally scanne vos canaux entrants (e-mail, réseaux sociaux...) pour de nouveaux leads.'
  },
  '_demo:automatic_lead_qualification:annotation-2': {
    en: 'Plurally automatically qualifies the leads based on your criteria and updates your CRM accordingly.',
    fr: 'Plurally qualifie automatiquement les leads en fonction de vos critères et met à jour votre CRM en conséquence.'
  },
  '_demo:automatic_lead_qualification:node-1:title': {
    en: 'Inbound',
    fr: 'Canaux entrants'
  },
  '_demo:automatic_lead_qualification:node-1:name': {
    en: 'New email',
    fr: 'Nouvel e-mail',
  },
  '_demo:automatic_lead_qualification:node-2:title': {
    en: 'HubSpot',
    fr: 'HubSpot'
  },
  '_demo:automatic_lead_qualification:node-2:name': {
    en: 'Qualify lead & update CRM',
    fr: 'Qualification du lead & mise à jour du CRM'
  },
  'Request a demo': {
    fr: 'Demander une démo',
  },
  'Getting started': {
    fr: 'Démarrez en quelques minutes',
  },
  'Optimize your sales worflow with CRM automation,': {
    fr: ''
  },
  'Integrates with': {
    fr: 'Intégration avec'
  },
  'And many more': {
    fr: 'Et des dizaines d\'autres'
  },
  'Don’t see the integration you need? You can request it!': {
    fr: 'Vous ne trouvez pas l\'intégration dont vous avez besoin ? Vous pouvez la demander !'
  },
  'Request an integration': {
    fr: 'Demander une intégration'
  },
  Welcome: {
    fr: 'Bienvenue'
  },
  'We answer quickly.': {
    fr: 'Nous répondons rapidement.'
  },
  'Have another question? Contact us!': {
    fr: 'Vous avez une autre question ? Contactez-nous !'
  },
  'How does it work?': {
    fr: 'Comment ça marche ?'
  },
  'Savings simulator': {
    fr: 'Simulateur d\'économies'
  },
  '_lp:simulator:title': {
    en: 'Focus on deals, not clicks',
    fr: 'Priorisez les deals, pas les clics'
  },
  '_lp:simulator:desc': {
    en: 'Use the simulator to find out how much you could save every month with Plurally.',
    fr: 'Utilisez le simulateur pour découvrir combien vous pourriez économiser chaque mois avec Plurally.'
  },
  'Number of salespeople in your company': {
    fr: 'Nombre de commerciaux dans votre entreprise'
  },
  'Hourly salary': {
    fr: 'Salaire horaire'
  },
  'Enter a custom number': {
    fr: 'Entrez un nombre'
  },
  '$ / hour': {
    fr: '€ / heure'
  },
  'New audio recording': {
    fr: 'Nouvel enregistrement audio'
  },
  'Save': {
    fr: 'Économisez',
  },
  'Reset': {
    fr: 'Réinitialiser'
  },
  '_lp:cost_simulator_assumptions': {
    en: 'Assumptions: Each salesperson works 20 days / month at ${hourlySalary} / hour and spends 1,5 hours / day entering data in the CRM.',
    fr: 'Hypothèses : Chaque commercial.le travaille 20 jours / mois à {hourlySalary}€ / heure et passe 1,5 heure / jour à saisir des données dans le CRM.'
  },
  '_lp:new_badge:content': {
    en: 'Integrate Plurally with Leexi',
    fr: 'Intégrez Plurally avec Leexi'
  },
  'every': {
    fr: 'chaque'
  },
  'month': {
    fr: 'mois'
  },
  'Frequently asked questions': {
    fr: 'Questions fréquentes'
  },
  'Step {step}': {
    fr: 'Étape {step}'
  },
  'Create a flow by using a template or from scratch': {
    fr: 'Créez un workflow en utilisant un modèle ou à partir de zéro',
  },
  'Connect the necessary integrations': {
    fr: 'Connectez les intégrations nécessaires',
  },
  'Monitor your flow activity': {
    fr: 'Surveillez l\'activité de votre workflow',
  },
  'Integrate your services': {
    fr: 'Intégrez vos services',
  },
  'Build your flow': {
    fr: 'Construisez votre workflow',
  },
  'Your flow is running!': {
    fr: 'Votre workflow est déployé !',
  },
  'First name': {
    fr: 'Prénom',
  },
  'Last name': {
    fr: 'Nom',
  },
  'Select CRM': {
    fr: 'Sélectionnez votre CRM',
  },
  'Specify CRM': {
    fr: 'Précisez votre CRM',
  },
  'Company': {
    fr: 'Entreprise',
  },
  'Other': {
    fr: 'Autre',
  },
  'Email': {
    fr: 'E-mail',
  },
  'Role': {
    fr: 'Rôle',
  },
  'Old Value': {
    fr: 'Ancienne valeur',
  },
  'New Value': {
    fr: 'Nouvelle valeur',
  },
  'Field': {
    fr: 'Champ',
  },
  'Continue': {
    fr: 'Continuer',
  },
  'Privacy Policy': {
    fr: 'Politique de confidentialité',
  },
  'Use cases': {
    fr: 'Cas d\'usage',
  },
  'Integrations': {
    fr: 'Intégrations',
  },
  'Pricing': {
    fr: 'Tarifs',
  },
  'Simulator': {
    fr: 'Simulateur',
  },
  'Submit': {
    fr: 'Soumettre',
  },
  'CRM automated actions': {
    fr: 'Actions automatisées dans votre CRM',
  },
  'Call created': {
    fr: 'Appel créé',
  },
  'Contact created': {
    fr: 'Contact créé',
  },
  'Deal created': {
    fr: 'Affaire créée',
  },
  'Contact associated': {
    fr: 'Contact associé',
  },
  'Deal associated': {
    fr: 'Affaire associée',
  },
  'Task created': {
    fr: 'Tâche créée',
  },
  'Task associated': {
    fr: 'Tâche associée',
  },
  'Note created': {
    fr: 'Note créée',
  },
  'Deal updated': {
    fr: 'Affaire mise à jour',
  },
  'Company updated': {
    fr: 'Entreprise mise à jour',
  },
  'Contact updated': {
    fr: 'Contact mis à jour',
  },
  'New': {
    fr: 'Nouveau',
  }
}