import { translations } from './translations'
import { localeDefault } from './locales'

function replaceArgs(text, args) {
  if (!args) {
    return text
  }
  return text.replace(/\{(\w+)}/g, (_, v) => args[v])
}

function translate(text, locale, args) {
  if (locale === localeDefault && !text.startsWith('_')) {
    return replaceArgs(text, args)
  }
  const textTranslations = translations[text]
  if (!textTranslations) {
    console.warn(`No translations for "${text}"`)
    return replaceArgs(text, args)
  }
  if (!textTranslations[locale]) {
    return text
  }

  return replaceArgs(textTranslations[locale], args)
}

export default translate