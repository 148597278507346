import translate from 'src/locales/translate'
import { usePageContext } from 'vike-react/usePageContext'

function T({ children, args }) {
    const pageContext = usePageContext()
    const { locale } = pageContext
    const text = children
    const textLocalized = translate(text, locale, args)
    return <>{textLocalized}</>
}

export default T;